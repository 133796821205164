:root {
    --light-sky-blue: #7ED1FF;
    --rich-black: #010620;
    --han-purple: #4A25E1;
    --tart-orange: #FF4A4A;
    --medium-turquoise: #58DDCD;
}

@font-face {
    font-family: "Mabry Pro";
    src: url("../fonts/mabry-pro-black.otf") format("opentype");
    font-weight: 900;
}

@font-face {
    font-family: "Mabry Pro";
    src: url("../fonts/mabry-pro-bold.ttf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: "Mabry Pro";
    src: url("../fonts/mabry-pro-medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Mabry Pro";
    src: url("../fonts/mabry-pro-regular.ttf") format("opentype");
    font-weight: 400;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: var(--light-sky-blue);
    font-family: "Mabry Pro";
    background-color: var(--rich-black);
    color: white;
    /* overflow-x: hidden; */
}

.unscrollable {
    overflow: hidden;
    height: 100vh;
}

img, video {
    width: 100%;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

@media screen and (orientation: portrait) {
    .mobile-hidden {
        display: none !important;
    }
}

@media screen and (orientation: landscape) {
    .desktop-hidden {
        display: none !important;
    }
}

.no-padding {
    padding-left: 0 !important;
}

/* --------------------------------------------- */

header {
    z-index: 3;
    position: absolute;
    width: 100%;
    text-align: center;
    padding: 25px 0;
    min-height: 90px;
    transition: top 1s, background-color 0.5s;
    top: -200px;
}

header .logo {
    margin: auto;
    width: 140px;
}

header > .hamburger {
    transition: top 1s;
    position: fixed;
    top: -180px;
    right: 20px;
    z-index: 5;
}

header a {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: white !important;
}

#menu {
    position: fixed;
    top: 0;
    transform: translateY(-100%);
    transition: transform 0.3s;
    background-color: var(--rich-black);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    width: 100%;
    z-index: 4;
    padding-bottom: 35px;
    padding-top: 25px;
}

#menu.is-active {
    transform: translateY(0);
}

.navbar-container {
    display: flex;
    justify-content: space-between;
}

.navbar-container > img {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

.navbar-section {
    display: flex;
    flex: 0 1 30%;
    align-items: flex-start;
    gap: 40px;
    margin: 0 60px;
    font-weight: 900;
    font-size: 18px;
    line-height: 25px;
}

.navbar-section-right {
    justify-content: end;
}

@media screen and (orientation: landscape) {
    header {
        position: fixed;
        background-color: rgba(1, 6, 32, 0);
    }

    header.navbar-background {
        background-color: rgba(1, 6, 32, 1);
    }
}

/* --------------------------------------------- */

.intro {
    height: 100vh;
    position: relative;
}

.intro p {
    margin: 0;
}

.intro > .video-overlay {
    position: absolute;
    font-weight: 900;
    color: white;
    margin: auto;

    top: 168px;
    left: 22px;
    width: calc(100vw - 44px);
    font-size: clamp(39px, 6vw, 90px);
    line-height: clamp(42px, 6vw, 90px);
}

@media screen and (min-width: 768px) and (orientation: portrait) {
    .intro > .video-overlay {
        font-size: clamp(75px, 10vw, 82px);
        line-height: clamp(42px, 10vw, 82px);
    }
}

@media screen and (orientation: landscape) {
    .intro > .video-overlay {
        left: 55px;
        width: calc(100vw - 44px - 55px);
        top: 240px;
    }
}

.opensea-button {
    box-shadow: none;
    border: 0;

    background-color: var(--medium-turquoise);
    color: white;
    text-align: center;
    font-weight: 900;
    font-size: 25px;
    line-height: 28px;
    border-radius: 50px;
    text-decoration: none;
    height: 60px;
    width: 247px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.intro > video {
    position: fixed;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
}

.intro > .scroll-text {
    font-weight: 900;
    position: absolute;
    bottom: 60px;
    font-size: 18px;
    left: calc(50% - 51px);
}

/* --------------------------------------------- */

.info {
    background-color: var(--rich-black);
    padding-bottom: 66px;
}

.info-layout {
    padding: 26px;
    max-width: 980px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.info-title {
    white-space: nowrap;
    padding: 57px 26px;
    font-size: clamp(40px, calc(6vw), 58px);
    line-height: clamp(40px, calc(6vw), 58px);
    font-weight: 900;
}

.info-description {
    font-size: 18px;
    line-height: 28px;
}

.info-description > .info-title {
    display: none;
}

@media screen and (min-width: 768px) {
    .info-title {
        display: none;
    }

    .info-description {
        flex: 0 1 50%;
    }

    .info-description > .info-title {
        display: block;
    }

    .info-description > p {
        max-width: 37ch;
    }

    .info-layout {
        padding: 57px clamp(38px, 5vw, 155px);
        flex-direction: row;
        gap: 30px;
    }

    .info-video {
        align-self: center;
        flex: 0 1 50%;
        order: 2;
    }
}

.slider-container {
    margin: auto;
    text-align: center;
    overflow: hidden;
}

.glide {
    width: 150%;
    transform: translate(-17%);
}

.glide__arrow {
    border: none;
    box-shadow: none;
}

.glide__bullets {
    position: static;
    margin-top: 50px;
    transform: none;
}

.glide__slide {
    cursor: pointer;
    transition: transform 0.3s;
    transform: scale(0.9);
}

.glide__slide--active {
    transform: scale(1);
}

/* --------------------------------------------- */


.roadmap {
    overflow: hidden;
    background: linear-gradient(to bottom, var(--light-sky-blue), var(--rich-black));
}

.roadmap-container {
    margin: auto;
    position: relative;
    max-width: 1200px;
}

.roadmap-goals {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 66px 26px;
}

.roadmap-title {
    font-weight: 900;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .roadmap-title {
        font-size: 60px;
        line-height: 60px;
    }
}

@media screen and (min-width: 1280px) {
    .roadmap-title {
        font-size: 70px;
        line-height: 70px;
    }
}

.roadmap-subtitle {
    margin-top: 15px;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 46px;
}

.roadmap-goal {
    display: flex;
    flex-direction: column;
    max-width: 560px;
    margin-top: 39px;
    gap: 10px;
    z-index: 2;
}

.roadmap-goal > .goal-target {
    white-space: nowrap;
    font-weight: 900;
    font-size: 35px;
    line-height: 35px;
    min-width: 4ch;
}

.roadmap-goal > .goal-description {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
}

.creators-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 26px 26px 100px 26px;
}

.creators-container > div {
    z-index: 2;
}

.creators-title {
    margin-top: 50px;
}

.cloud {
    width: 390px;
    position: absolute;
    display: none;
}

.cloud-box {
    top: 182px;
    right: -225px;
    display: block;
}

.cloud-mask {
    top: 642px;
    left: -116px;
    display: block;
}

.cloud-ball {
    top: 1200px;
    right: -105px;
    display: block;
}

@media screen and (min-width: 768px) {
    .cloud-left {
        left: -188px;
    }

    .cloud-right {
        right: -155px;
    }

    .cloud-far-left {
        left: -800px;
    }

    .cloud-far-right {
        right: -800px;
    }

    .cloud-box {
        top: -40px;
    }
    
    .cloud-mask {
        top: 300px;
    }
    
    .cloud-ball {
        top: 864px;
    }

    .cloud-goku {
        top: 1100px;
        display: block;
    }
    
    .cloud-unicorn {
        top: 1649px;
        display: block;
    }

    .cloud-plain {
        top: 2180px;
        display: block;
    }

    .cloud-gold-head {
        top: -40px;
        display: block;
    }
    
    .cloud-sword-tilted {
        top: 300px;
        display: block;
    }
    
    .cloud-snitch {
        top: 864px;
        display: block;
    }

    .cloud-balloon {
        top: 1100px;
        display: block;
    }
    
    .cloud-pokeball {
        top: 1649px;
        display: block;
    }

    .cloud-sword-straight {
        top: 2180px;
        display: block;
    }

    .roadmap-subtitle {
        width: 830px;
        max-width: 67vw;
    }
}

@media screen and (min-width: 1280px) {
    .creators-title {
        margin-top: 200px;
    }

    .roadmap-goal {
        flex-direction: row;
        max-width: 690px;
        gap: 35px;
        margin-top: 65px;
    }
}

.creators {
    display: flex;
    flex-direction: column;
    max-width: 860px;
}

.creator {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

.creator.reverse {
    flex-direction: row-reverse;
}

.creator-profile {
    flex: 0 0 140px;
}

.creator-profile > img {
    border-radius: 50%;
}

.creator-name {
    white-space: nowrap;
    font-size: 40px;
    line-height: 40px;
    font-weight: 900;
}

.creator-title {
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
}

.creator-social {
    padding-top: 5px;
}

.creator-social img {
    width: 18px;
    height: 18px;
}

@media screen and (min-width: 768px) {
    .creators {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 60px;
    }

    .creator {
        flex-basis: 27%;
        flex-direction: column;
    }

    .creator.reverse {
        flex-direction: column;
    }

    .creator-name, .creator-title {
        text-align: center;
    }

    .creator-social img {
        width: 22px;
        height: 22px;
    }
}

/* --------------------------------------------- */

.community {
    background-color: var(--han-purple);
}

.discord {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 26px;
}

.discord-text {
    padding: 26px 0;
}

.discord-title {
    text-align: left;
}

.discord-description {
    margin-top: 36px;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
}

.discord-button {
    box-shadow: none;
    border: 0;

    background-color: var(--tart-orange);
    color: white;
    text-align: center;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    padding: 8px 22px;
    border-radius: 50px;
    margin-top:44px;
}

.discord-image {
    width: 100%;
    align-self: flex-end;
}

.discord-image > img {
    max-height: 436px;
    object-fit: contain;
    display: block;
}

@media screen and (min-width: 768px) {
    .discord {
        flex-direction: row;
    }
    .discord-text {
        padding: 26px;
        flex: 0 1 60%;
    }

    .discord-image {
        flex: 0 1 40%;
        width: auto;
        margin-top: 80px;
    }
}

@media screen and (min-width: 1280px) {
    .discord-text {
        padding: 0;
    }
}

/* --------------------------------------------- */

footer {
    background-color: var(--rich-black);
}

.footer-container {
    padding: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    gap: 20px;
}

footer a {
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
}

.footer-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-logo {
    max-width: 140px;
    flex: 0 0 30%;
}

.links {
    flex: 0 0 40%;
    min-width: 94px;
    display: flex;
    flex-direction: column;
}

.icons {
    display: flex;
    gap: 16px;
}

.terms {
    margin-bottom: 20px;
}

.icons img {
    width: 26px;
}

.copyrights {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
}

@media screen and (min-width: 768px) {
    footer {
        padding: 66px clamp(67px, 79vw, 91px) 35px;
    }

    .footer-container {
        gap: 31px;
    }
    
    .copyrights {
        align-self: flex-start;
    }
}


@keyframes bounce {
    0%,
    20%,
    53%,
    100% {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -30px, 0) scaleY(1.1);
    }

    70% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -15px, 0) scaleY(1.05);
    }
  
    80% {
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0, 0) scaleY(0.95);
    }

    90% {
      transform: translate3d(0, -4px, 0) scaleY(1.02);
    }
}

.bounce {
    animation-name: bounce;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-duration: 3s;
    transform-origin: center bottom;
}


@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 50%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
}

.fade-up-target {
    opacity: 0;
}

.fadeInUp {
    animation-name: fadeInUp;
    animation-fill-mode: both;
    animation-duration: 1s;
}

@keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-50%, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
}

.fade-left-target {
    opacity: 0;
}

.fadeInLeft {
    animation-name: fadeInLeft;
    animation-fill-mode: both;
    animation-duration: 1s;
}

@keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(50%, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
}

.fade-right-target {
    opacity: 0;
}

.fadeInRight {
    animation-name: fadeInRight;
    animation-fill-mode: both;
    animation-duration: 1s;
}
